<template>
    <div class="kath-sz-card">
        <v-data-table
                :headers="headers"
                :items="Kirchengemeinden.kirchengemeinden"
                class="elevation-1"
                items-per-page="items-per-page"
                :pagination.sync="Kirchengemeinden.pagination"
        >
            <template slot="items" slot-scope="props">
                <tr @click="openLink(props.item.link, '_blank')"
                    class="link"
                >
                    <td>{{ props.item.location }}</td>
                    <td class="text-xs-right">{{ props.item.members }}</td>
                    <td class="text-xs-right">{{ props.item.leaders }}</td>
                    <td class="text-xs-right">{{ props.item.voices }}</td>
                </tr>
            </template>

        </v-data-table>
    </div>
</template>

<script>
    export default {
        name: "KirchengemeindenTable",
        computed: {
            headers () {
                return this.$store.getters.kirchgemeinden.headers
            },
            Kirchengemeinden () {
                return this.$store.getters.kirchgemeinden
            }
        },
        methods: {
            openLink (page) {
                window.open(page, '_blank')
            }
        }
    }
</script>

<style scoped>

</style>